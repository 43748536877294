export enum KPIType {
	PRVN = 'PRVN',
	CSAT = 'CSAT',
}

export enum AuditPeriod {
	QUARTERLY = 'QUARTERLY',
	HALFYEARLY = 'HALFYEARLY',
	YEARLY = 'YEARLY',
	NA = 'NA',
	INVALID = 'INVALID',
}

/**
 * ? NOTE: We already have an interface "IProcessVineLogs" in src/model/IProcessVineLogs.tsx
 * ? But that interface is not helpful as it has null to almost all the fields, it's not safely typed.
 * ? This is a minimal type which is strongly typed so components can freed from all the null/undefined checks
 * ! This doesn't contain all the data, it's very minimal. But can be expanded as per use cases
 */
export type KPILog = {
	id: number;
	accountId: number;
	requestedScore: number;
	requestedDate: Date;
	requestedBy: number;
	approvedScore: number;
	approvedDate: Date;
	approvedBy: number;
	finalizedScore: number;
	finalizedDate: Date;
	finalizedBy: number;
	status: number;
};

export enum KpiActivity {
	REQUEST = 'REQUEST',
	APPROVE = 'APPROVE',
	FINALIZE = 'FINALIZE',
	REJECTED = 'REJECTED',
	NOT_STARTED = 'NOT_STARTED',
}

export type KPIScoreBaseParams = {
	id?: number,
	kpi: number,
	accountId: number,
	projectId: number,
}

export type RequestScoreParams = KPIScoreBaseParams & {
	requestedScore: number,
	requesterComments: string,
}

export type ApproveScoreParams = KPIScoreBaseParams & {
	approvedScore: number,
	approverComments: string,
}

export type FinalizeScoreParams = KPIScoreBaseParams & {
	finalizedScore: number,
	finalizerComments: string,
}

export type RejectScoreParams = KPIScoreBaseParams & {
	finalizerComments: string,
}

export type KPIBaseResponse = {
	error: string,
	message: string,
	status: number,
}

export type Project = {
	id: number,

	clientId: number,
	client_name: string,

	project_name: string,
	project_status: string,
	project_type: string,
    deptName: string,
	csatscore: number,
	tscore: number,
};